<template>
    <!-- The Modal -->
    <div class="modal fade" id="configEditModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Edit Configuration</h4>
                    <button type="button" class="close" data-dismiss="modal" @click="$emit('close')">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row editor">
                            <div class="col-12 col-sm-12">
                                <div class="form" role="form" style="">
                                    <!-- section one -->
                                    <div class="row">
                                        <div class="col-12"><h5>Settings</h5><br></div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Name <strong class="text-danger">*</strong></label>
                                                <input type="text" v-model="item.name" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Website <strong class="text-danger">*</strong></label>
                                                <input type="text" v-model="item.url" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Status <strong class="text-danger">*</strong></label>
                                                <select v-model="item.status" class="form-control text-capitalize required" required>
                                                    <option v-for="(status, index) in statuses" :key="index" :value="status">{{ status }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Date Range <strong class="text-danger">*</strong></label>
                                                <ejs-daterangepicker cssClass="e-outline" v-model="item.daterange" ></ejs-daterangepicker>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Proxy Provider<strong class="text-danger">*</strong></label>
                                                <select v-model="item.proxy_provider" @change="loadcountries" class="form-control text-capitalize required" required>
                                                    <option value="">Any</option>
                                                    <option v-for="(provider, index) in providers" :value="provider.provider" :key="index">{{  provider.provider }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Proxy Country<strong class="text-danger">*</strong></label>
                                                <select v-model="item.proxy_country" @change="loadcountries" class="form-control text-capitalize required" required>
                                                    <option value="">Any</option>
                                                    <option v-for="(country, index) in countries" :value="country.code" :key="index">{{  country.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Device <strong class="text-danger">*</strong></label>
                                                <select v-model="item.device" class="form-control text-capitalize required" required>
                                                    <option v-for="(device, index) in devices" :key="index" :value="device.value">{{ device.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12">
                                <label>Form Script<strong class="text-danger">*</strong></label>
                                <codemirrow :item="item.code" :FillItem="editCode" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="row pt-4">
                        <div class="col-12">
                            <div class="form-group text-center">
                                <button v-if="!item._id" type="submit" class="btn btn-primary btn-sm"
                                        @click.prevent="save">
                                    <span class="glyphicon glyphicon-ok"></span> Create Configuration
                                </button>
                                <button v-else type="submit" class="btn btn-primary btn-sm"
                                        @click.prevent="update">
                                    <span class="glyphicon glyphicon-ok"></span> Update Configuration
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import Vue from 'vue';
import axios from "axios";
import codemirrow from "../shared/mirror.vue";

import { DateRangePickerPlugin } from '@syncfusion/ej2-vue-calendars';
Vue.use(DateRangePickerPlugin);

export default {
    name: "FormFillModal",
    props: [
        "item",
        "save",
        "update"
    ],
    components: { codemirrow },
    data() {
        return {
            args: {},
            ready: false,
            isLoading: false,
            start: 50,
            method: "",
            search: "",
            selected: [],
            providers: [],
            countries: [],
            datapoints: null,
            statuses: ["active", "disabled"],
            width: "100%",
            height: "300px",
            itemtemplate: "<div class='list-wrapper'><span class='text'>${text}<span style='margin-left: 11px;' class='e-icons e-delete'></span></div>",
            saved: null,
            csvData: [],
            csvHeaders: [],
            devices: [
                {name: "All", value: ""},
                {name: "Mobile", value: "yes"},
                {name: "Tablet", value: "tablet"},
                {name: "Desktop", value: "no"},
            ]
        }
    },
    mounted() {
        this.getProviders();
        this.loadcountries();
    },
    computed: {},
    methods: {
        editCode: function (code) {
            //Edit the vendor item
            this.item.code = code;
        },
        getProviders() {
            //Load the providers
            // this.$root.preloader = true;
            this.providers = [];

            axios.get(`${this.$root.serverUrl}/admin/proxies/providers`).then(function(resp){
                this.$root.preloader = false;
                this.providers = (resp && resp.data && resp.data.data) ? resp.data.data: [];
            }.bind(this));
        },
        loadcountries: function(){
            return axios.get(`${this.$root.serverUrl}/admin/countries`).then(function(resp){
                //Store the stats
                this.countries = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
    }
};
</script>

<style scoped>
@import "../../../node_modules/@syncfusion/ej2-vue-diagrams/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";

.modal-dialog {
    height: 1000px;
    display: flex;
    max-width: 800px;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}

.modal-body {
    overflow: scroll;
}
.info-sec i {
    font-size: 100px;
}
.v-select2-container {
    width: 100%; /* Set the width to 100% */
}
.select2-container .select2-selection--single {
    height: 38px; /* Set the width to 100% */
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 38px;
}
.e-listbox {
    min-height: 400px;
}
</style>
