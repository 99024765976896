<template>
  <div class="mt-3">
    <codemirror
      ref="mirror"
      v-model="item"
      :options="cmOptions"
      @input="onCodeChange"
    />
  </div>
</template>

<script>
// import { codemirror } from "vue-codemirror";
import "codemirror/mode/javascript/javascript";
import "codemirror/lib/codemirror.css";
// import "codemirror/theme/base16-dark.css";
// import "codemirror/theme/blackboard.css";
import "codemirror/theme/the-matrix.css";

export default {
  props: ["item", "FillItem"],
  components: {
    // codemirror
  },
  data: function () {
    return {
      code: "const a = 10;",
      cmOptions: {
        tabSize: 4,
        mode: "text/javascript",
        theme: "the-matrix",
        lineNumbers: true,
        line: true,
        rtlMoveVisually: true,
      },
    };
  },
  computed: {
    codemirror() {
      return this.$refs.mirror ? this.$refs.mirror.codemirror : null;
    },
  },
  mounted() {
    console.log("ref: ", this.$refs);
    console.log("ref:mirror: ", this.$refs.mirror);
    if (this.item) {
      this.item = this.item == "" ? this.code : this.item;
    }
    this.code = this.item ? this.item : this.code;
  },
  methods: {
    onCodeChange(code) {
      console.log("onCodeChange: ", code);
      this.code = code;
      if (this.FillItem) {
        console.log("onCodeChange:FillItem ", this.item);
        this.FillItem(code);
        console.log("onCodeChange:FillItem ", this.item);
      }
    },
  },
};
</script>

<style>
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  height: 700px !important;
}
</style>