<template>
    <!-- The Modal -->
    <div class="modal fade" id="configLeadsModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Configuration Leads</h4>
                    <button type="button" class="close" data-dismiss="modal" @click="$emit('close')">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row editor">
                            <div class="col-12 col-sm-12 text-right mb-4">
                                <div class="btn-group">
                                    <button type='button' class='btn btn-sm btn-secondary' data-toggle="dropdown" :disabled="item.status === 'active'"><i class="fa fa-upload" title="leads"> Import Leads</i></button>
                                    <div class="dropdown-menu" role="menu">
                                        <button class="dropdown-item" @click="upload('replace')">Replace</button>
                                        <button class="dropdown-item" @click="upload('append')">Append</button>
                                    </div>
                                    <input
                                        style="display: none;"
                                        class="hidden"
                                        type="file"
                                        accept=".csv"
                                        ref="uploadObj"
                                        @change="fileupload"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-sm-12">
                                <ejs-grid ref="grid" :dataSource="data"
                                          :isResponsive="true" :allowPaging="true" :allowFiltering="true" :allowSorting="true"
                                          :pageSettings="pageSettings" :actionBegin="actionBegin">
                                    <e-columns>
                                        <e-column field="status" headerText="Status" width="180"></e-column>
                                        <e-column v-for="header in headers" :field="`data.${header}`" :headerText="header" text-Align="Left" width="160" :key="header.name"></e-column>
                                        <e-column :template="actionTemplate" width="180"></e-column>
                                    </e-columns>
                                </ejs-grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import swal from "sweetalert";

export default {
    name: "FormFillLeadsModal",
    props: ["item"],
    components: { },
    data() {
        return {
            isLoading: false,
            selected: [],
            data: null,
            page: 1,
            strategy: "replace",
            file: null,
            uploaded: 0,
            pageSettings: {
                pageSize: 25,
                pageSizes: [25, 50, 100, 250, 500, 1000],
                currentPage: 1,
                totalRecordsCount: 127
            },
            itemtemplate: "<div class='list-wrapper'><span class='text'>${text}<span style='margin-left: 11px;' class='e-icons e-delete'></span></div>",
            actionTemplate: function () {
                return {
                    template: Vue.component('actionTemplate', {
                            template: `
                              <div class="d-flex" style="gap: 5px;">
                                <button class="btn btn-sm btn-danger" @click="remove"
                                ><i class="fa fa-trash" title="Delete"></i></button>
                            </div>
                            `,
                            data: function () {
                                return {
                                    data: {},
                                }
                            },
                            methods: {
                                remove: function () {
                                    //Handle removing the item
                                    swal({
                                        title: "Remove",
                                        text: "Are you sure?",
                                        icon: "info",
                                        buttons: ["No", "Yes"],
                                    }).then((val) => {
                                        if (!val) return;

                                        var request = {id: this.data._id};
                                        // this.$root.preloader = true;

                                        axios({
                                            url: `${this.$root.serverUrl}/admin/formfill/config/leads/${this.data._id}`,
                                            data: request,
                                            responseType: "json",
                                            method: "DELETE",
                                        })
                                            .then((resp) => {
                                                //Process the results
                                                this.$root.preloader = false;
                                                if (resp.data && !resp.data.error) {
                                                    //Saved successfully, now update the parent
                                                    swal({
                                                        title: "Remove",
                                                        text: "Your request was completed successfully",
                                                        icon: "success",
                                                    })
                                                } else {
                                                    //Handle errors
                                                    swal({
                                                        title: "Remove",
                                                        text: resp.data.error.message,
                                                        icon: "error",
                                                    });
                                                }
                                            })
                                            .catch((err) => {
                                                this.$root.preloader = false;
                                                this.errormsg = err.message || "An unexpected error occurred";
                                                swal({title: "Error", text: this.errormsg, icon: "error"});
                                            });
                                    })
                                }
                            }
                        }
                    )
                }
            },
        }
    },
    mounted() {
        this.search();
    },
    computed: {
        headers() {
            if (!this.data || !this.data.result || !this.data.result.length) return [];
            return Object.keys(this.data.result[0].data);
        }
    },
    methods: {
        fileupload() {
            this.file = this.$refs.uploadObj.files[0];

            swal({
                title: "Upload leads",
                text: `Are you sure you want to ${ this.strategy == 'replace' ? "upload and replace the": "append to"} existing leads`,
                icon: "info",
                buttons: ["No", "Yes"],
            }).then((val) => {
                if (!val) return;

                this.uploadFile();
            })
        },
        upload(strategy) {
            this.strategy = strategy;
            this.$refs.uploadObj.click()
        },
        async uploadFile() {
            this.loaded = 0;
            const request = new FormData();
            request.append("file", this.file);
            request.append("strategy", this.strategy);

            axios.post(`${this.$root.serverUrl}/admin/formfill/config/leads/${this.item._id}`, request, {
                onUploadProgress: progressEvent => this.loaded = (progressEvent.loaded/progressEvent.total) * 100,
                headers: {contentType: 'multipart/form-data'}
            }).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    swal({ title: 'Error', text: "Unable to process your request", icon: 'error' })
                } else {
                    this.search()
                }
            }).catch((err) => {
                console.log(err)
                swal({ title: 'Error', text: "Unable to process your request", icon: 'error' })
            })
        },
        actionBegin: function(args) {
            if(args.requestType === "paging") {
                this.page = args.currentPage;
                this.search()
            }
        },
        search() {
            let request = {$skip: (this.page - 1) * this.pageSettings.pageSize, $top: this.pageSettings.pageSize, filters: {}};
            axios.get(`${this.$root.serverUrl}/admin/formfill/config/leads/${this.item._id}`, {params: request}).then((resp) => {
                this.data = {result: resp.data.Items, count: resp.data.Count};
                this.$root.preloader = false;
                this.$forceUpdate()
            }).catch((err) => {
                console.log(err)
            });
        },
    }
};
</script>

<style scoped>
.modal-dialog {
    height: 1000px;
    display: flex;
    max-width: 100%;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}

.modal-body {
    overflow: scroll;
}
.info-sec i {
    font-size: 100px;
}
</style>
